<template>
  <div>
    <create :is-add-new-page-sidebar-active.sync="isShowFormAdd" :is-loading="isLoading" :type-form="typeForm"
      :data="returnReceiveData" @onSubmit="onSubmit" />
    <b-card>
      <own-table ref="returnReceiveTable" name-table="return-receive" :table-columns="columns" :use-custom-filter="true"
        :custom-query-filter="queryFilter" @searchFieldOnChange="searchOnChange" :export-excel="false"
        sort-by="created_at" sort-direction="desc" @evt-add="() => {
          isShowFormAdd = true
          typeForm = 'add'
        }">
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(is_active)="data">
          <b-badge :variant="statusVariant(data.data.item.is_active)">
            <span>{{
              data.data.item.is_active === 1 ? 'Aktif' : 'Tidak Aktif'
            }}</span>
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(start_date)="data">
          <span>{{ formatDate(data.data.item.start_date) }}</span>
        </template>
        <template #cell(expire_date)="data">
          <!-- <span>{{ moment(data.data.item.expire_date).format('DD-MM-YYYY') }}</span> -->
          <span>{{ formatDate(data.data.item.expire_date) }}</span>
        </template>
        <template #cell(value)="data">
          <span>{{
            data.data.item.type === 'fixed' ||
            data.data.item.type === 'shipping'
            ? formatCurrency(data.data.item.value)
            : `${data.data.item.value}%`
          }}</span>
        </template>
        <template #cell(min_transaction_value)="data">
          <span>{{
            formatCurrency(data.data.item.min_transaction_value)
          }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <feather-icon icon="EyeIcon" size="16" class="align-middle text-body"
              @click="$router.push({ name: 'apps-returpenerimaan-view', params: { id: data.data.item.id } })" />
            <b-dropdown variant="link" toggle-class="p-0" menu-class="position-absolute" no-caret
              :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="edit(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import Create from './components/Create'
import service from '@/components/Table/service'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    Create,
  },
  setup() {
    const returnReceiveTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Nomor Retur',
        key: 'number',
      },
      {
        label: 'Tgl. Retur',
        key: 'return_date',
      },
      {
        label: 'Keterangan',
        key: 'note',
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const returnReceiveData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      console.log(typeForm.value)
      if (typeForm.value === 'add') {
        service.store({ url: 'return-receive', data: formData }).then(res => {
          returnReceiveTable.value.refetchData()
          isShowFormAdd.value = false
        }).catch(err => {
          console.log(err)
          const element = Vue.component('description', {
            render: h => serializeErrorMessage(h, err),
          })
          const toast = createToastInterface({
            timeout: 6000,
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Add Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              description: element,
            },
          })
        }).finally(() => {
          isLoading.value = false
        })
      } else {
        service.update({ url: 'return-receive', data: formData }).then(res => {
          returnReceiveTable.value.refetchData()
          isShowFormAdd.value = false
        }).catch(err => {
          console.log(err)
          const element = Vue.component('description', {
            render: h => serializeErrorMessage(h, err),
          })
          const toast = createToastInterface({
            timeout: 6000,
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Edit Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              description: element,
            },
          })
        }).finally(() => {
          isLoading.value = false
        })
      }
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      returnReceiveData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const setStatus = data => {
      const form = data
      form.is_active = data.is_active === 1 ? 0 : 1
      returnReceiveTable.value.onEdit()
      returnReceiveTable.value.submit(form, () => { })
    }

    const edit = data => {
      typeForm.value = 'edit'
      returnReceiveTable.value.onEdit()
      returnReceiveData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      returnReceiveTable.value.onDelete(id)
    }
    // const formatDateTime = date => formatDate(date)
    return {
      columns,
      queryFilter,
      returnReceiveTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      setStatus,
      edit,
      returnReceiveData,
      typeForm,
      onDelete,
    }
  },
}
</script>
